<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col">{{ $t('tablasNutricionales.energia_y_nutrientes') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.unidades') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.cada100ml' , {pv:"19,2"} ) }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>Kcal</td>
        <td>82</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>Kj</td>
        <td>342</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>g</td>
        <td>12,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>g</td>
        <td>2,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>g</td>
        <td>2,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>g</td>
        <td>0,85</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_monoinsaturadas') }}</td>
        <td>g</td>
        <td>1,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_poliinsaturadas') }}</td>
        <td>g</td>
        <td>0,18</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colesterol') }}</td>
        <td>mg</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>g</td>
        <td>0</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>g</td>
        <td>0,53</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="3">{{ $t('tablasNutricionales.minerales') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>mg</td>
        <td>37</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>mg</td>
        <td>94</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cloro') }}</td>
        <td>mg</td>
        <td>72</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>mg</td>
        <td>70</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>mg</td>
        <td>49</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.magnesio') }}</td>
        <td>mg</td>
        <td>7,7</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.hierro') }}</td>
        <td>mg</td>
        <td>1,3</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.zinc') }}</td>
        <td>mg</td>
        <td>0,35</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cobre') }}</td>
        <td>mcg</td>
        <td>48</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.manganeso') }}</td>
        <td>mg</td>
        <td>0,08</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fluor') }}</td>
        <td>mg</td>
        <td>0,05</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.molibdeno') }}</td>
        <td>mcg</td>
        <td>1,9</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.selenio') }}</td>
        <td>mcg</td>
        <td>2,3</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cromo') }}</td>
        <td>mcg</td>
        <td>1,9</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.iodo') }}</td>
        <td>mcg</td>
        <td>14</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="3">{{ $t('tablasNutricionales.vitaminas') }}</th>
      </tr>

      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_a') }}</td>
        <td>mcg RE</td>
        <td>58</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_d') }}</td>
        <td>mcg</td>
        <td>1,8</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_e') }}</td>
        <td>mg alfa TE</td>
        <td>2,0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_k') }}</td>
        <td>mcg</td>
        <td>5,8</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.tiamina_vit_b_1') }}</td>
        <td>mg</td>
        <td>0,08</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.riboflavina_vit_b_2') }}</td>
        <td>mg</td>
        <td>0,13</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.niacina_vit_b_3') }}</td>
        <td>mg</td>
        <td>0,58</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_pantotenico') }}</td>
        <td>mg</td>
        <td>0,50</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.piridoxina_vit_b_6') }}</td>
        <td>mg</td>
        <td>0,07</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_folico') }}</td>
        <td>mcg</td>
        <td>12</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cianocobalamina_vit_b_12') }}</td>
        <td>mcg</td>
        <td>0,17</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.biotina') }}</td>
        <td>mcg</td>
        <td>2,9</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_c') }}</td>
        <td>mg</td>
        <td>12</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colina') }}</td>
        <td>mg</td>
        <td>21</td>
      </tr>
       <tr>
        <td colspan="3">{{ $t('tablasNutricionales.osmolaridad', {osmolaridad: 150, solutos:200}) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableHangPackJuniorSinLactosa",
};
</script>
